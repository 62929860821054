import updateHoneyPot from './updateHoneyPot';

export default () => {
  const runUpdate = () => {
    const forms = document.querySelectorAll('form.js-calc-form, form.js-form');

    if (!forms.length) {
      return false;
    }

    for (const form of forms) {
      const formHandle = form.dataset.handle;
      if (formHandle) {
        fetch(`/ajax/getFormData?form=${formHandle}`)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            const hashField = form.querySelector('input[name=formHash]');
            if (hashField) {
              hashField.value = response.hash;
            }

            // Update the JS Honeypot (ONLY when JS Enhancement setting is ON)
            updateHoneyPot(form, response);

            // Locate and update the CSRF input
            const { csrf } = response;
            form.querySelector(`input[name=${csrf.name}]`).value = csrf.value;
            form.classList.add('is-updated');
          });
      }
    }
  };

  document.addEventListener('afterBlitzInject', runUpdate);
};
