import SlimSelect from 'slim-select';

export default (form, selectsArr = []) => {
  // const selects = document.querySelectorAll('.js-slim-select');
  const selects = form.querySelectorAll(
    '.js-slim-select:not(#priceCalculator-fromLanguage):not(#priceCalculator-toLanguage)'
  );
  const calcSourceLangFld = form.querySelector('#priceCalculator-fromLanguage');
  const calcTargetLangFld = form.querySelector('#priceCalculator-toLanguage');

  const setActive = (info) => {
    const option = document.querySelector(`.ss-option[data-id="${info.id}"]`);
    if (option) {
      option.closest('.ss-main').classList.add('is-active');
    }
  };

  selects.forEach((item) => {
    const { limit } = item.dataset;
    let showSearch = true;
    if (item.classList.contains('no-search')) {
      showSearch = false;
    }
    const select = new SlimSelect({
      limit: limit || 10,
      select: item,
      showSearch,
      hideSelectedOption: true,
      onChange: setActive,
      searchFilter: (option, search) => {
        const opt = option.text.substr(0, search.length).toLowerCase();
        return opt === search.toLowerCase();
      },
    });

    selectsArr.push(select);
  });

  if (calcSourceLangFld != null && calcTargetLangFld != null) {
    const calcSourceGroup = document.getElementById('calc-source-group');
    const poplangs = JSON.parse(calcSourceGroup.dataset.poplangs);
    const otherlangs = JSON.parse(calcSourceGroup.dataset.otherlangs);

    let calcSourceLangSelect;
    let calcTargetLangSelect;
    let selectedLangs;
    let sourceVal;
    let targetVal;

    const checkSelectedLangs = () => {
      selectedLangs = [];
      sourceVal = calcSourceLangFld.value;
      targetVal = [...calcTargetLangFld.options].filter((option) => option.selected).map((option) => option.value);
      selectedLangs = targetVal.concat([sourceVal]);
    };

    const updateSourceLangList = (item) => {
      setActive(item);
      checkSelectedLangs();
      const checkLang = (lang) => {
        lang.selected = sourceVal === lang.value;
        if (selectedLangs.indexOf(lang.value) !== -1) {
          lang.class = 'opacity-50 hidden';
        } else {
          lang.class = 'ss-option';
        }
        return lang;
      };
      const popLangsArr = [
        { text: calcSourceLangFld.dataset.placeholder, placeholder: true },
        {
          label: 'Popular languages',
          options: poplangs.map(checkLang),
        },
      ];
      const newList = popLangsArr.concat(otherlangs.map(checkLang));
      calcSourceLangSelect.setData(newList);
    };

    const updateTargetLangList = (item) => {
      setActive(item);
      checkSelectedLangs();
      const checkLang = (lang) => {
        lang.selected = targetVal.indexOf(lang.value) !== -1;
        if (selectedLangs.indexOf(lang.value) !== -1) {
          lang.class = 'opacity-50 hidden';
        } else {
          lang.class = 'ss-option';
        }
        return lang;
      };
      const popLangsArr = [
        { text: calcTargetLangFld.dataset.placeholder, placeholder: true },
        {
          label: 'Popular languages',
          options: poplangs.map(checkLang),
        },
      ];
      const newList = popLangsArr.concat(otherlangs.map(checkLang));
      calcTargetLangSelect.setData(newList);
    };

    calcSourceLangSelect = new SlimSelect({
      select: calcSourceLangFld,
      hideSelectedOption: true,
      // onChange: updateTargetLangList,
      searchFilter: (option, search) => {
        const opt = option.text.substr(0, search.length).toLowerCase();
        return opt === search.toLowerCase();
      },
    });

    calcTargetLangSelect = new SlimSelect({
      select: calcTargetLangFld,
      hideSelectedOption: true,
      // onChange: updateSourceLangList,
      searchFilter: (option, search) => {
        const opt = option.text.substr(0, search.length).toLowerCase();
        return opt === search.toLowerCase();
      },
    });

    selectsArr.push(calcSourceLangSelect);
    selectsArr.push(calcTargetLangSelect);
  }

  return selectsArr;
};
