/* global dataLayer */
/* eslint no-underscore-dangle: ["error", { "allow":
 ["_updateMaxFilesReachedClass", "_errorProcessing", "_enqueueThumbnail"]
 }] */
/* eslint no-restricted-syntax: ["off", "ForOfStatement"] */
/* eslint func-names: ["error", "never"] */

import pluralize from 'pluralize';
import MoveTo from 'moveto';
import Pristine from 'pristinejs';
import Dropzone from 'dropzone';
import numeral from 'numeral';
// import updateHoneyPot from './updateHoneyPot';
import initSimSelects from './slimSelects/initSimSelects';
import clearSlimSelects from './slimSelects/clearSlimSelects';

Dropzone.autoDiscover = false;
Dropzone.prototype.isFileExist = function (file) {
  let i;
  if (this.files.length > 0) {
    for (i = 0; i < this.files.length; i += 1) {
      if (
        this.files[i].name === file.name &&
        this.files[i].size === file.size &&
        this.files[i].lastModifiedDate.toString() === file.lastModifiedDate.toString()
      ) {
        return true;
      }
    }
  }
  return false;
};
Dropzone.prototype.addFile = function (file) {
  file.upload = {
    progress: 0,
    total: file.size,
    bytesSent: 0,
  };
  if (this.options.preventDuplicates && this.isFileExist(file)) {
    return;
  }
  this.files.push(file);
  file.status = Dropzone.ADDED;
  this.emit('addedfile', file);
  this._enqueueThumbnail(file);
  return this.accept(
    file,
    (function (_this) {
      return function (error) {
        if (error) {
          file.accepted = false;
          _this._errorProcessing([file], error);
          // console.log('dz error', error);
        } else {
          file.accepted = true;
          if (_this.options.autoQueue) {
            _this.enqueueFile(file);
          }
        }
        return _this._updateMaxFilesReachedClass();
      };
    })(this)
  );
};

export default () => {
  const form = document.getElementById('js-quote-form');
  if (form == null) {
    return false;
  }

  const selectsArr = initSimSelects(form);

  const myDropzone = new Dropzone('#js-dz-quote', {
    url: '/quote/words',
    paramName: 'files',
    clickable: ['#js-dz-addmore', '#js-dz-quote'],
    autoProcessQueue: true,
    uploadMultiple: true,
    parallelUploads: 100,
    maxFiles: 10,
    maxFilesize: 50,
    previewsContainer: '.dropzone-previews',
    addRemoveLinks: true,
    dictDuplicateFile: 'Duplicate Files Cannot Be Uploaded',
    preventDuplicates: true,
    createImageThumbnails: false,
    acceptedFiles: 'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf,.txt,.odt',
  });
  const dropzonePreviewsContainer = form.querySelector('.dropzone-previews');
  const quoteTotalWordCount = document.getElementById('js-total-words-count');

  let commonPrice = null;
  let wordCount = null;
  // let rateCommon = 1;
  let resultFiles = [];
  let formErrors = [];
  let uploadErrors = [];
  // const translateLangsRatesIds = [];
  let translateLangsRates = [];
  let urls = [];
  let subjectMatterRate = null;
  let deliverySpeedRate = null;
  let subjectMatter = null;
  let deliverySpeed = null;
  let fromLang = null;
  let fromLangLabel = null;
  let subjectMatterLabel = null;
  let deliverySpeedLabel = null;
  let toLangsLabels = null;

  let toLangs = [];
  let checkoutFormIsValid = true;
  let isWordCountRevalidationActive = false;

  const calcFormBtn = document.getElementById('form_page_calculate');
  const checkoutSubmitButton = document.getElementById('form_page_submit');

  let calculatorHandle = 'priceCalculator';
  if (!document.getElementById(`${calculatorHandle}-fromLanguage`)) {
    calculatorHandle = 'priceCalculator1';
  }

  const calcFormFields = form.querySelectorAll(
    `#${calculatorHandle}-fromLanguage,` +
      ` #${calculatorHandle}-toLanguage, #p${calculatorHandle}-subjectMatter,` +
      ` #${calculatorHandle}-deliverySpeed, #${calculatorHandle}-wordCount`
  );
  const fromLanguageField = document.getElementById(`${calculatorHandle}-fromLanguage`);
  const toLanguageField = document.getElementById(`${calculatorHandle}-toLanguage`);
  const subjectMatterField = document.getElementById(`${calculatorHandle}-subjectMatter`);
  const deliverySpeedField = document.getElementById(`${calculatorHandle}-deliverySpeed`);
  // const wordCountField = document.getElementById('form-input-wordCount');
  const quoteFormWordCount = document.querySelector(`#${calculatorHandle}-wordCount`);
  const quoteFormErrorContainer = document.querySelector('#form-error__container');

  const contentStart = document.getElementById('js-content-start');
  const calcSection = document.getElementById('js-calc-section');
  const pricingPackagesSection = document.querySelector('#instant-quote-form__quotes');
  const checkoutSection = document.getElementById('js-checkout-section');
  const quoteCrumbsNav = document.getElementById('js-crumb-nav');
  const quoteCrumbsContainer = document.getElementById('js-crumb-container');

  const checkoutFromLangContainer = document.getElementById('js-checkout-from-language');
  const checkoutToLangContainer = document.getElementById('js-checkout-to-language');
  const checkoutSubjectContainer = document.getElementById('js-checkout-subject');
  const checkoutWordsContainer = document.getElementById('js-checkout-word-count');
  const checkoutDeliveryContainer = document.getElementById('js-checkout-delivery');
  const checkoutPkgOptionContainer = document.getElementById('js-checkout-option');
  const checkoutFilesCountContainer = document.getElementById('js-checkout-files-count');
  const checkoutPriceContainer = document.getElementById('js-checkout-result-price');

  const totalPriceFld = document.getElementById('form-input-totalPrice');
  const priceOptionFld = document.getElementById('form-input-priceOptions');
  const editOrderBtn = document.getElementById('js-quote-edit-order');

  const infoSections = document.getElementById('js-info-sections');
  const helpSections = document.getElementById('js-help-sections');

  const formDataset = form.dataset;

  const baseUrl = document.querySelector('meta[name="baseUrl"]').content;

  numeral.register('locale', 'fr', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    currency: {
      symbol: '€',
    },
  });
  numeral.locale('fr');
  numeral.defaultFormat('$0,0.00');

  const renderCheckoutFormErrors = () => {
    const checkoutErrorContainer = document.getElementById('js-checkout-error-container');
    if (checkoutErrorContainer.querySelector('*') !== null) {
      return false;
    }
    const errorBlock = document.createElement('div');
    const heading = document.createElement('h3');
    const paragraph = document.createElement('p');

    const headingInner = form.dataset.errorHeading;
    const paragraphInner = form.dataset.errorText;

    errorBlock.classList.add('ff-form-errors', 'form-errors');
    errorBlock.classList.add('p-20p', 'mt-20p', 'rounded-sm', 'bg-red-10', 'border', 'border-red-30', 'mb-0');
    heading.classList.add('text-16p', 'font-semibold', 'text-red', 'mb-10p');
    paragraph.classList.add('text-14p');

    heading.appendChild(document.createTextNode(headingInner));
    paragraph.appendChild(document.createTextNode(paragraphInner));
    errorBlock.appendChild(heading);
    errorBlock.appendChild(paragraph);

    checkoutErrorContainer.appendChild(errorBlock);
  };

  const hideCheckoutErrors = () => {
    const checkoutErrorContainer = document.getElementById('js-checkout-error-container');
    checkoutErrorContainer.innerHTML = '';
  };

  const enableLoading = () => {
    calcFormBtn.classList.add('is-loading');
    checkoutSubmitButton.classList.add('is-loading');
  };

  const disableLoading = () => {
    calcFormBtn.classList.remove('is-loading');
    checkoutSubmitButton.classList.remove('is-loading');
  };

  const lockSubmit = () => {
    enableLoading();
    calcFormBtn.setAttribute('disabled', true);
    checkoutSubmitButton.setAttribute('disabled', true);
  };

  const unlockSubmit = () => {
    disableLoading();
    calcFormBtn.removeAttribute('disabled');
    checkoutSubmitButton.removeAttribute('disabled');
  };

  const showErrorBlock = () => {
    // console.log('showErrorBlock');
    const calcErrors = formErrors.concat(uploadErrors);
    let errorHtml = '';
    // calcErrors.forEach((error) => (errorHtml += `<div>${error}</div>`));
    for (const error of calcErrors) {
      errorHtml += `<p>${error}</p>`;
    }
    quoteFormErrorContainer.innerHTML = errorHtml;
    quoteFormErrorContainer.classList.remove('hidden');
  };

  const checkResultFilesForErrors = (filesArray) => {
    let isResultContainsBrokenFiles = false;
    for (const file of filesArray) {
      if (file.error) {
        // console.log('isResultContainsBrokenFiles');
        isResultContainsBrokenFiles = true;
      }
    }
    // console.log(isResultContainsBrokenFiles);
    return isResultContainsBrokenFiles;
  };

  const lockWordCountField = (lock = true) => {
    const group = quoteFormWordCount.closest('.form-group');
    if (lock) {
      group.classList.add('is-locked');
    } else {
      group.classList.remove('is-locked');
    }
  };

  const scrollTo = (targetEl) => {
    setTimeout(() => {
      const moveTo = new MoveTo();
      moveTo.move(targetEl);
    }, 100);
  };

  const showSection = (el) => {
    el.classList.remove('hidden');
    el.classList.remove('lg:hidden');
  };

  const hideSection = (el) => {
    el.classList.add('hidden');
    el.classList.add('lg:hidden');
  };

  const validateCalcForm = () => {
    // clearFormError();
    formErrors = [];
    let isValid = true;
    for (const field of calcFormFields) {
      const fldParent = field.closest('.form-group');
      let errorMsg = fldParent.dataset.help;

      if (field.value === '0') {
        errorMsg = formDataset.formWordCount;
        fldParent.querySelector('.text-help').innerHTML = errorMsg;
        field.closest('.form-group').classList.add('has-danger');
        isValid = false;
      } else if (!field.value) {
        fldParent.querySelector('.text-help').innerHTML = errorMsg;
        field.closest('.form-group').classList.add('has-danger');
        isValid = false;
      } else {
        field.closest('.form-group').classList.remove('has-danger');
      }
    }
    if (!isValid) {
      formErrors.push(formDataset.formDefaultError);
      showErrorBlock();
    } else {
      formErrors = [];
      showErrorBlock();
    }

    return isValid;
  };

  const changeBtnLabel = (btnEl) => {
    if (!btnEl || !btnEl.dataset.label) {
      return false;
    }

    btnEl.innerText = btnEl.dataset.label;
  };

  const getRates = (cb) => {
    // store urls to fetch in an array
    const getSelectedLabel = (selectFld) => {
      return selectFld.options[selectFld.selectedIndex].text;
    };

    subjectMatter = subjectMatterField.value;
    deliverySpeed = deliverySpeedField.value;
    fromLang = fromLanguageField.value;
    toLangs = [...toLanguageField.options].filter((option) => option.selected).map((option) => option.value);

    fromLangLabel = getSelectedLabel(fromLanguageField);
    subjectMatterLabel = getSelectedLabel(subjectMatterField);
    deliverySpeedLabel = getSelectedLabel(deliverySpeedField);
    toLangsLabels = [...toLanguageField.options].filter((option) => option.selected).map((option) => option.text);

    // console.log('subjectMatter', subjectMatter);
    // console.log('deliverySpeed', deliverySpeed);
    // console.log('fromLang', fromLang);
    // console.log('toLangs', toLangs);
    // console.log('fromLangLabel', fromLangLabel);
    // console.log('toLangsLabels', toLangsLabels);

    translateLangsRates = [];
    urls = [`/ajax/getSubjectRate?subject=${subjectMatter}`, `/ajax/getDeliveryRate?delivery=${deliverySpeed}`];

    for (const toLang of toLangs) {
      urls.push(`/ajax/getLanguagesRate?sourceLang=${fromLang}&targetLang=${toLang}`);
    }

    // console.log('request urls', urls);

    Promise.all(urls.map((url) => fetch(url)))
      .then((resp) => Promise.all(resp.map((r) => r.json())))
      .then((result) => {
        for (const item of result) {
          if ('subjectRate' in item) {
            subjectMatterRate = item.subjectRate;
          }
          if ('deliveryRate' in item) {
            deliverySpeedRate = item.deliveryRate;
          }
          if ('sourceLang' in item) {
            translateLangsRates.push(item);
          }
        }
        // console.log('multiple rates data', result);

        // console.log('subjectMatterRate', subjectMatterRate);
        // console.log('deliverySpeedRate', deliverySpeedRate);
        // console.log('translateLangsRates', translateLangsRates, typeof translateLangsRates);

        cb();
      })
      .catch(() => {
        unlockSubmit();
        // console.log(error);
      });
  };

  const calculateRate = () => {
    // rateCommon = subjectMatterRate * deliverySpeedRate;
    // console.log('rateCommon', rateCommon);
  };

  const getWordCount = () => {
    const words = parseInt(quoteFormWordCount.value, 10);
    // console.log('getWordCount', words);
    return Number.isNaN(words) ? 0 : words;
  };

  const ucFirst = (str) => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  };

  const switchCrumb = (targetCrumb) => {
    const allCrumbs = quoteCrumbsNav.querySelectorAll('.js-button');
    const currCrumb = quoteCrumbsNav.querySelector(`.js-button[data-target=${targetCrumb}]`);
    // console.log('currCrumb', currCrumb);
    for (const item of allCrumbs) {
      item.classList.remove('is-active');
      // item.classList.remove('is-current');
    }
    currCrumb.removeAttribute('disabled');
    currCrumb.classList.add('is-active');
    currCrumb.classList.add('is-current');
  };

  const selectPackageListener = (pkg, price) => {
    const pkgBtn = pkg.querySelector('.js-submit');
    pkgBtn.addEventListener('click', (e) => {
      const target = e.currentTarget;
      const pkgEl = target.closest('.js-pricing-plan');
      const pkgName = pkgEl.dataset.name;
      // console.log('selected package', pkgName);

      const toLangsFormatted = [];
      for (const lang of toLangs) {
        toLangsFormatted.push(ucFirst(lang));
      }

      checkoutFromLangContainer.innerText = fromLangLabel;
      checkoutToLangContainer.innerText = toLangsLabels.join(', ');
      checkoutWordsContainer.innerText = pluralize(formDataset.formWordTerm, wordCount, true);
      checkoutSubjectContainer.innerText = subjectMatterLabel;
      checkoutDeliveryContainer.innerText = deliverySpeedLabel;
      checkoutPkgOptionContainer.innerText = ucFirst(pkgName);
      checkoutPriceContainer.innerText = numeral(price).format();
      checkoutFilesCountContainer.innerText = resultFiles.length;

      totalPriceFld.value = price.toFixed(2);
      priceOptionFld.value = ucFirst(pkgName);

      hideSection(calcSection);
      showSection(checkoutSection);

      // setTimeout(() => {
      scrollTo(contentStart);
      switchCrumb('checkout');
      // }, 50);
      setTimeout(() => {
        hideSection(pricingPackagesSection);
        hideSection(infoSections);
        showSection(helpSections);
      }, 1000);
    });
  };

  const checkBasePackage = (pkg) => {
    if (!pkg) {
      return false;
    }

    const pkgBtn = pkg.querySelector('.js-submit');
    const pkgAllowedMatters = pkg.dataset.allowedSubjects;
    const pkgAllowedLangs = pkg.dataset.allowedLangs;

    // console.log('fromLang', fromLang);
    // console.log('toLangs', toLangs);

    let enablePkg = false;

    if (fromLang === 'english') {
      // console.log('from eng');
      enablePkg = true;
      for (const item of toLangs) {
        if (pkgAllowedLangs.indexOf(item) < 0) {
          enablePkg = false;
        }
      }
    }

    if (fromLang !== 'english') {
      // console.log('from not eng');
      if (pkgAllowedLangs.indexOf(fromLang) !== -1) {
        enablePkg = true;
        if (toLangs.length === 1 && toLangs[0].indexOf('english') !== -1) {
          enablePkg = true;
        } else {
          enablePkg = false;
        }
      }
    }

    if (pkgAllowedMatters.indexOf(subjectMatter) < 0) {
      enablePkg = false;
    }

    if (enablePkg === true) {
      pkg.classList.remove('disabled');
      pkg.closest('.js-package-item').classList.remove('is-hidden');
      pkgBtn.removeAttribute('disabled');
    } else {
      pkg.classList.add('disabled');
      pkg.closest('.js-package-item').classList.add('is-hidden');
      pkgBtn.setAttribute('disabled', 'true');
    }
  };

  const setPackagesPrices = () => {
    // console.log('setPackagesPrices');

    const pricingPackages = pricingPackagesSection.querySelectorAll('.js-pricing-plan');

    for (const pkg of pricingPackages) {
      const packageRate = pkg.dataset.rate;
      const commonPriceContainer = pkg.querySelector('.js-common-price');
      const wordPriceContainer = pkg.querySelector('.js-word-price');
      let wordPrice = 0;

      for (const langRate of translateLangsRates) {
        wordPrice += langRate.rateValue * packageRate * subjectMatterRate * deliverySpeedRate;
      }

      commonPrice = wordPrice * wordCount;
      commonPriceContainer.innerText = numeral(commonPrice).format();
      wordPriceContainer.innerText = numeral(wordPrice).format();

      selectPackageListener(pkg, commonPrice);
    }

    checkBasePackage(pricingPackages[0]);
  };

  const validaCalcOnChange = () => {
    isWordCountRevalidationActive = true;
    for (const field of calcFormFields) {
      field.addEventListener('change', () => {
        if (!validateCalcForm()) {
          return false;
        }
      });
    }
  };

  const validaCheckoutOnChange = () => {
    const checkoutFieldGroups = checkoutSection.querySelectorAll('.form-group input, .form-group textarea');

    const checkFields = () => {
      let isCheckOutFieldsValid = true;

      for (const field of checkoutFieldGroups) {
        const group = field.closest('.form-group');
        if (group.classList.contains('has-danger')) {
          isCheckOutFieldsValid = false;
        }
      }

      if (isCheckOutFieldsValid) {
        hideCheckoutErrors();
      }
    };

    for (const field of checkoutFieldGroups) {
      field.addEventListener('change', () => {
        checkFields();
      });
    }
  };

  // const recountRate = () => {
  //   for (const field of calcFormFields) {
  //     field.addEventListener('change', () => {
  //       lockSubmit();
  //       if (!validateCalcForm()) {
  //         return false;
  //       }
  //       getRates(() => {
  //         setPackagesPrices();
  //         calculateRate();
  //
  //         showSection(pricingPackagesSection);
  //         setTimeout(() => {
  //           scrollTo(pricingPackagesSection);
  //           unlockSubmit();
  //         }, 200);
  //       });
  //     });
  //   }
  // };

  const clearFormError = () => {
    quoteFormErrorContainer.classList.add('hidden');
    quoteFormErrorContainer.innerHTML = '';
    formErrors = [];
    uploadErrors = [];
  };

  const resultWordCount = () => {
    // console.log('resultWordCount');
    wordCount = 0;
    clearFormError();
    resultFiles.forEach((element, index) => {
      // console.log('element => ', element);
      if (element.error === 1) {
        // console.log('element.error === 1');
        let currMsg;
        switch (element.error_code) {
          case 'file_error_type':
            currMsg = `${element.name} ${formDataset.formTypeError}`;
            break;
          case 'file_maybe_empty':
            currMsg = formDataset.formEmptyError;
            break;
          default:
            currMsg = `${formDataset.formAccessError} ${element.name}`;
        }
        if (!(currMsg in uploadErrors)) {
          uploadErrors.push(currMsg);
        }
        showErrorBlock();
      }

      if (element.error === 0 && element.count_words > 0) {
        // console.log('resultWordCount / element / words', element.name, element.count_words);
        wordCount += element.count_words;
      }

      const previews = dropzonePreviewsContainer.querySelectorAll('.dz-preview:not(.dz-error)');
      const currentPreview = previews[index];
      // console.log('currentPreview', currentPreview);

      if (!currentPreview.querySelector('.dz-wordcount')) {
        const wordCountElem = document.createElement('div');
        let fileWordCount = 'Uncountable';
        wordCountElem.className = 'dz-wordcount';
        // console.log('element', element);
        if (typeof element.count_words !== 'undefined') {
          // console.log('element.count_words', element.count_words);
          fileWordCount = pluralize(formDataset.formWordTerm, element.count_words, true);
        } else {
          wordCountElem.classList.add('text-red');
        }
        wordCountElem.innerHTML = fileWordCount;
        currentPreview.append(wordCountElem);
      }
    });
    quoteFormWordCount.value = wordCount;
    quoteTotalWordCount.innerHTML = pluralize(formDataset.formWordTerm, wordCount, true);
  };

  const resultAddFiles = (result) => {
    resultFiles.push(result);
    resultWordCount();
    // console.log('resultFiles length: ', resultFiles.length, resultFiles);
    if (resultFiles.length > 0) {
      if (!checkResultFilesForErrors(resultFiles)) {
        lockWordCountField();
      } else {
        lockWordCountField(false);
      }
    }
  };

  const resultRemoveFiles = (file) => {
    // console.log('parseResultFromFiles resultFiles => ', resultFiles);
    const resultFilesNew = [];
    resultFiles.forEach((element) => {
      // console.log(element);
      if (element.name !== file.name) {
        resultFilesNew.push(element);
      }
    });
    resultFiles = resultFilesNew;
    // console.log('parseResultFromFiles resultFiles => ', resultFiles);
    resultWordCount();

    // console.log('resultFiles length: ', resultFiles.length);
    if (!checkResultFilesForErrors(resultFiles)) {
      lockWordCountField();
    } else {
      lockWordCountField(false);
    }
    if (resultFiles.length === 0) {
      lockWordCountField(false);
    }
  };

  myDropzone.on('success', (file, response) => {
    response.filter((p) => p.name === file.name);
    response.forEach((element) => {
      if (element.name === file.name) {
        resultAddFiles(element);
        // console.log('file.name', file.name);
      }
    });
    if (isWordCountRevalidationActive) {
      validateCalcForm();
    }
  });

  myDropzone.on('sending', (file, xhr, formData) => {
    formData.append('CRAFT_CSRF_TOKEN', document.getElementsByName('CRAFT_CSRF_TOKEN')[0].value);
  });

  myDropzone.on('removedfile', (file) => {
    // console.log('dz removedfile');
    // const ref = file.previewElement;
    resultRemoveFiles(file);
    if (isWordCountRevalidationActive) {
      validateCalcForm();
    }
  });

  myDropzone.on('error', (errorMessage) => {
    // console.log('dz error. response:', errorMessage);
    lockWordCountField(false);
    if (errorMessage.size / 10000 > 50) {
      uploadErrors.push(`${errorMessage.name} is too big. The maximum upload file size is 50Mb.`);
      if (isWordCountRevalidationActive) {
        validateCalcForm();
      }
      showErrorBlock();
    }
  });

  const fixCrumbs = (target) => {
    const ioParams = { rootMargin: '0px' };
    const io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          quoteCrumbsNav.classList.remove('is-sticky');
        } else {
          quoteCrumbsNav.classList.add('is-sticky');
        }
      });
    }, ioParams);

    io.observe(target);
  };

  fixCrumbs(quoteCrumbsContainer);

  quoteCrumbsNav.querySelector('.js-button[data-target=calc]').addEventListener('click', () => {
    hideSection(checkoutSection);
    showSection(calcSection);
    switchCrumb('calc');

    setTimeout(() => {
      scrollTo(contentStart);
    }, 0);

    setTimeout(() => {
      hideSection(pricingPackagesSection);
      hideSection(helpSections);
      hideSection(infoSections);
    }, 200);
  });

  quoteCrumbsNav.querySelector('.js-button[data-target=pricing]').addEventListener('click', () => {
    hideSection(checkoutSection);
    showSection(calcSection);
    showSection(pricingPackagesSection);
    switchCrumb('pricing');

    setTimeout(() => {
      scrollTo(pricingPackagesSection);
      hideSection(infoSections);
      hideSection(helpSections);
    }, 100);
  });

  quoteCrumbsNav.querySelector('.js-button[data-target=checkout]').addEventListener('click', () => {
    hideSection(calcSection);
    showSection(checkoutSection);
    switchCrumb('checkout');

    setTimeout(() => {
      scrollTo(contentStart);
    }, 0);
    setTimeout(() => {
      hideSection(pricingPackagesSection);
      hideSection(infoSections);
      hideSection(helpSections);
    }, 200);
  });

  form.addEventListener('submit', (e) => {
    e.preventDefault();
  });

  calcFormBtn.addEventListener('click', (e) => {
    e.preventDefault();
    lockSubmit();
    if (!validateCalcForm()) {
      validaCalcOnChange();
      unlockSubmit();
      return false;
    }

    getRates(() => {
      wordCount = getWordCount();
      // console.log('wordCount', typeof wordCount, wordCount);
      if (!wordCount > 0) {
        return false;
      }
      // showErrorBlock();
      setPackagesPrices();
      calculateRate();

      showSection(pricingPackagesSection);
      scrollTo(pricingPackagesSection);
      switchCrumb('pricing');
      unlockSubmit();
      hideSection(infoSections);
      hideSection(helpSections);
      setTimeout(() => {
        changeBtnLabel(calcFormBtn);
      }, 1000);
    });
    // recountRate();
  });

  editOrderBtn.addEventListener('click', () => {
    hideSection(checkoutSection);
    showSection(calcSection);
    switchCrumb('calc');
  });

  const submitForm = () => {
    lockSubmit();
    const pristine = new Pristine(form, {
      classTo: 'form-group',
      errorClass: 'has-danger',
      successClass: 'has-success',
      errorTextParent: 'form-group',
      errorTextTag: 'div',
      errorTextClass: 'text-help is-hidden',
    });

    checkoutFormIsValid = pristine.validate();

    if (checkoutFormIsValid) {
      // submit form here
      const formData = new FormData(form);
      const acceptedFiles = myDropzone.getAcceptedFiles();
      if (myDropzone.files.length > 0) {
        acceptedFiles.forEach((file) => {
          formData.append('uploadedFile[]', file);
        });
      }
      // console.log(acceptedFiles);
      // for (const pair of formData.entries()) {
      // console.log(`${pair[0]}, ${pair[1]}`);
      // }

      const stripTrailingSlash = (str) => {
        return str.endsWith('/') ? str.slice(0, -1) : str;
      };

      const request = new XMLHttpRequest();
      request.open('POST', `${stripTrailingSlash(baseUrl)}/${formData.get('action')}`, /* async = */ false);
      request.setRequestHeader('Cache-Control', 'no-cache');

      // Set the Craft specific AJAX headers
      request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'XMLHttpRequest');

      request.onload = function () {
        if (request.status === 200) {
          const response = JSON.parse(request.response);

          if (response.success && response.finished) {
            form.reset();
            clearSlimSelects(selectsArr);

            // dataLayer event
            if (typeof dataLayer === 'object') {
              dataLayer.push({
                event: 'formSubmission',
                formType: form.dataset.handle,
              });
            }

            const { returnUrl } = response;
            if (returnUrl !== '/freeform/api/form') {
              // console.log('redirect');
              if (returnUrl[0] == '?' && returnUrl[0] == '/') {
                window.location.href = window.location.href + returnUrl;
              } else {
                window.location.href = `${window.location.href}/${returnUrl}`;
              }
            } else {
              // console.log('Form posted successfully');
              // console.log('no redirect');

              setInterval(() => {
                window.location.reload();
              }, 500);
            }
          } else if (response.errors || response.formErrors) {
            // console.log('response is not successful', response);
            // console.log('response.error', response.error);
            // console.log('response.formErrors', response.formErrors);

            renderCheckoutFormErrors();
            unlockSubmit();
          }

          // updateHoneyPot(form, response);
        } else {
          // console.error('request.status !== 200', request.response);
          unlockSubmit();
        }
      };

      request.send(formData);
      // quoteForm.submit();
    } else {
      validaCheckoutOnChange();
      renderCheckoutFormErrors();
      unlockSubmit();
    }
  };

  checkoutSubmitButton.addEventListener('click', (e) => {
    e.preventDefault();
    submitForm();
  });
};
