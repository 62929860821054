/* global dataLayer */

import Dropzone from 'dropzone';
import updateHoneyPot from './updateHoneyPot';
import initSimSelects from './slimSelects/initSimSelects';
import clearSlimSelects from './slimSelects/clearSlimSelects';

export default () => {
  Dropzone.autoDiscover = false;

  // console.log('deault form');
  const removeElements = (collection) => {
    if (!collection.length) {
      return false;
    }
    for (let i = collection.length - 1; i >= 0; i -= 1) {
      if (collection[i] && collection[i].parentElement) {
        collection[i].parentElement.removeChild(collection[i]);
      }
    }
  };

  const renderFormSuccess = (form) => {
    const successBlock = document.createElement('div');
    const heading = document.createElement('h3');
    const paragraph = document.createElement('p');

    const headingInner = form.dataset.successHeading;
    const paragraphInner = form.dataset.successText;

    if (form.classList.contains('inversed-form')) {
      successBlock.classList.add('ff-form-errors', 'form-errors');
      successBlock.classList.add('p-20p', 'mt-20p', 'rounded-sm', 'bg-green-10', 'max-w-440p', 'mx-auto');
      heading.classList.add('text-16p', 'font-semibold', 'text-green', 'mb-5p');
      paragraph.classList.add('text-14p', 'text-black');
    } else if (form.classList.contains('footer-subscribe-form')) {
      successBlock.classList.add('ff-form-success', 'form-success');
      successBlock.classList.add('mt-10p');
      heading.classList.add('text-16p', 'font-semibold', 'text-green');
      paragraph.classList.add('text-14p', 'text-green');
    } else {
      successBlock.classList.add('ff-form-success', 'form-success');
      successBlock.classList.add('p-20p', 'mt-20p', 'rounded-sm', 'bg-green-10');
      heading.classList.add('text-16p', 'font-semibold', 'text-green', 'mb-10p');
      paragraph.classList.add('text-14p');
    }

    if (form.closest('.bg-gray-30')) {
      successBlock.classList.add('border', 'border-green-30');
    }

    heading.appendChild(document.createTextNode(headingInner));
    paragraph.appendChild(document.createTextNode(paragraphInner));
    successBlock.appendChild(heading);
    successBlock.appendChild(paragraph);

    form.appendChild(successBlock);
  };

  const removeMessages = (form) => {
    // Remove any existing errors that are being shown
    removeElements(form.querySelectorAll('.errors'));
    const fieldsWithErrors = form.querySelectorAll('.has-error');
    for (let fieldIndex = 0; fieldIndex < fieldsWithErrors.length; fieldIndex += 1) {
      const field = fieldsWithErrors[fieldIndex];
      field.classList.remove('has-error', 'opacity-100');
    }

    // Remove success messages
    removeElements(form.querySelectorAll('.form-success'));
    removeElements(document.getElementsByClassName('form-errors'));
  };

  const renderErrors = (errors, form) => {
    const keys = Object.keys(errors);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const messages = errors[key];
      const errorsList = document.createElement('ul');
      errorsList.classList.add('errors', 'text-help');

      for (let messageIndex = 0; messageIndex < messages.length; messageIndex += 1) {
        const message = messages[messageIndex];
        const listItem = document.createElement('li');
        listItem.appendChild(document.createTextNode(message));
        errorsList.appendChild(listItem);
      }

      const inputList = form.querySelectorAll(`*[name=${key}], *[name='${key}[]']`);
      for (let inputIndex = 0; inputIndex < inputList.length; inputIndex += 1) {
        const input = inputList[inputIndex];

        input.parentElement.classList.add('has-error', 'opacity-100');
        input.parentElement.appendChild(errorsList);

        input.addEventListener('change', (e) => {
          const targetFld = e.currentTarget;
          removeElements(targetFld.parentElement.querySelector('.errors'));
          targetFld.parentElement.classList.remove('has-error', 'opacity-100');
        });
      }
    }
  };

  const renderFormErrors = (errors, form) => {
    const errorBlock = document.createElement('div');
    const heading = document.createElement('h3');
    const paragraph = document.createElement('p');

    const headingInner = form.dataset.errorHeading;
    const paragraphInner = form.dataset.errorText;

    if (form.classList.contains('inversed-form')) {
      errorBlock.classList.add('ff-form-errors', 'form-errors');
      errorBlock.classList.add('p-20p', 'mt-20p', 'rounded-sm', 'bg-gray-50', 'max-w-440p', 'mx-auto');
      heading.classList.add('text-16p', 'font-semibold', 'text-red', 'mb-5p');
      paragraph.classList.add('text-14p', 'text-red');
    } else if (form.classList.contains('footer-subscribe-form')) {
      errorBlock.classList.add('ff-form-errors', 'form-errors');
      errorBlock.classList.add('mt-20p');
      heading.classList.add('text-16p', 'font-semibold', 'text-red', 'hidden');
      paragraph.classList.add('text-14p', 'text-red', 'hidden');
    } else {
      errorBlock.classList.add('ff-form-errors', 'form-errors');
      errorBlock.classList.add('p-20p', 'mt-20p', 'rounded-sm', 'bg-red-10');
      heading.classList.add('text-16p', 'font-semibold', 'text-red', 'mb-10p');
      paragraph.classList.add('text-14p');
    }

    if (form.closest('.bg-gray-30')) {
      errorBlock.classList.add('border', 'border-red-30');
    }

    heading.appendChild(document.createTextNode(headingInner));
    paragraph.appendChild(document.createTextNode(paragraphInner));
    errorBlock.appendChild(heading);
    errorBlock.appendChild(paragraph);
    if (errors.length > 0) {
      const errorsList = document.createElement('ul');
      errors.forEach((error) => {
        const errorItem = document.createElement('li');
        errorItem.innerHTML = error;
        errorsList.appendChild(errorItem);
      });
      errorBlock.appendChild(errorsList);
    }

    form.appendChild(errorBlock);
  };

  const lockSubmit = (form) => {
    form.classList.add('is-loading');
    const submitButton = form.querySelector('button[type=submit]');
    submitButton.setAttribute('disabled', 'true');
    submitButton.classList.add('is-loading');
  };

  const unlockSubmit = (form) => {
    form.classList.remove('is-loading');
    const submitButton = form.querySelector('button[type=submit]');
    submitButton.removeAttribute('disabled');
    submitButton.classList.remove('is-loading');
    // if (typeof grecaptcha !== 'undefined') {
    //   grecaptcha.reset();
    // }
  };

  const ajaxifyForms = () => {
    const forms = document.querySelectorAll('.js-form');

    for (let formIndex = 0; formIndex < forms.length; formIndex += 1) {
      const form = forms[formIndex];

      if (!form.dataset.ajaxified) {
        form.dataset.ajaxified = 'true';

        const selectsArr = initSimSelects(form);

        let myDropzone = null;
        const fileUploadElement = form.querySelector('.js-file-upload');
        const previewsList = form.querySelector('.js-files-list');
        const previewsContainer = form.querySelector('.js-files-container');
        const dropzoneExists = fileUploadElement !== null;
        let resultFiles = [];

        if (dropzoneExists) {
          const maxFilesCount = Number(fileUploadElement.dataset.fileCount);
          myDropzone = new Dropzone(fileUploadElement, {
            url: '/quote/upload',
            paramName: 'files',
            autoProcessQueue: true,
            uploadMultiple: true,
            parallelUploads: 100,
            maxFiles: maxFilesCount,
            previewsContainer,
            addRemoveLinks: true,
            dictDuplicateFile: 'Duplicate Files Cannot Be Uploaded',
            preventDuplicates: true,
            createImageThumbnails: false,
            maxFilesize: 20,
            acceptedFiles: 'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.rtf,.txt',
          });

          // eslint-disable-next-line no-unused-vars
          myDropzone.on('addedfile', (file) => {
            const formGroup = myDropzone.element.closest('.form-group');
            if (formGroup !== null) {
              formGroup.classList.remove('has-error');
            }
          });

          const resultAddFiles = (result) => {
            resultFiles.push(result);

            if (previewsList !== null && resultFiles.length > 0) {
              if (previewsList.closest('.bg-gray-30')) {
                previewsContainer.classList.add('dark-theme');
              }

              previewsList.classList.remove('hidden');
            }
          };

          const resultRemoveFiles = ({ name }) => {
            const resultFilesNew = [];
            resultFiles.forEach((element) => {
              if (element.name !== name) {
                resultFilesNew.push(element);
              }
            });
            resultFiles = resultFilesNew;

            if (previewsList != null && resultFiles.length === 0) {
              previewsList.classList.add('hidden');
            }
          };

          myDropzone.on('sending', (file, xhr, formData) => {
            formData.append('CRAFT_CSRF_TOKEN', document.getElementsByName('CRAFT_CSRF_TOKEN')[0].value);
          });

          myDropzone.on('removedfile', (file) => {
            resultRemoveFiles(file);
            fileUploadElement.removeAttribute('disabled');
            fileUploadElement.classList.remove('opacity-75', 'cursor-not-allowed');
            fileUploadElement.classList.add('cursor-pointer');
          });

          myDropzone.on('maxfilesreached', () => {
            fileUploadElement.setAttribute('disabled', 'true');
            fileUploadElement.classList.remove('cursor-pointer');
            fileUploadElement.classList.add('opacity-75', 'cursor-not-allowed');
          });

          myDropzone.on('success', ({ name }, response) => {
            response.filter((item) => item.name === name);
            response.forEach((element) => {
              if (element.name === name) {
                resultAddFiles(element);
              }
            });
          });
        }

        form.addEventListener(
          'submit',
          (e) => {
            const currentForm = e.currentTarget;
            lockSubmit(currentForm);
            const data = new FormData(currentForm);

            if (dropzoneExists) {
              const acceptedFiles = myDropzone.getAcceptedFiles();
              if (myDropzone.files.length > 0) {
                acceptedFiles.forEach((file) => {
                  data.append(fileUploadElement.dataset.handle, file);
                });
              }
            }

            const request = new XMLHttpRequest();
            const method = currentForm.getAttribute('method');
            const action = currentForm.getAttribute('action');

            request.open(method, action || window.location.href, true);
            request.setRequestHeader('Cache-Control', 'no-cache');
            request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'XMLHttpRequest');
            request.onload = () => {
              removeMessages(currentForm);

              if (request.status === 200) {
                const response = JSON.parse(request.response);
                // console.log('request.status === 200', response);

                if (response.success && response.finished) {
                  // Reset the form so that the user may enter fresh information
                  currentForm.reset();
                  clearSlimSelects(selectsArr);

                  if (dropzoneExists) {
                    myDropzone.removeAllFiles(true);
                  }

                  setTimeout(() => {
                    removeElements(form.querySelectorAll('.form-success'));
                  }, 10000);

                  if (response.returnUrl !== window.location.pathname) {
                    window.location.href = response.returnUrl;
                  }

                  renderFormSuccess(currentForm);

                  // dataLayer event
                  if (typeof dataLayer === 'object') {
                    dataLayer.push({
                      event: 'formSubmission',
                      formType: currentForm.dataset.handle,
                    });
                  }
                } else if (response.errors || response.formErrors) {
                  renderErrors(response.errors, currentForm);
                  renderFormErrors(response.formErrors, currentForm);
                  // console.log('request.status !== 200', response.errors, response);
                }

                updateHoneyPot(form, response);
                unlockSubmit(currentForm);
              } else {
                // console.error(request);
              }

              unlockSubmit(currentForm);
            };

            request.send(data);
            e.preventDefault();
          },
          false
        );
      }
    }
  };

  ajaxifyForms();
};
