export default (form, response) => {
  const { honeypot } = response;

  if (honeypot.hash) {
    const honeypotBaseName = honeypot.name.substring(0, honeypot.name.lastIndexOf('_'));
    const honeypotField = form.querySelector(`input[name^="${honeypotBaseName}"]`);
    if (!honeypotField) {
      return false;
    }
    honeypotField.setAttribute('id', honeypot.name);
    honeypotField.setAttribute('name', honeypot.name);
    honeypotField.value = honeypot.hash;
  }
};
